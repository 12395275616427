import React from 'react';
import tw from 'twin.macro';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { ArrowIcon } from './Icons';

const capitalizeFirstLetter = (str) => {
  const s = str.replace('/', '');
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const Breadcrumbs = ({ post, ...rest }) => {
  const { origin, pathname } = useLocation();
  if (pathname === '/') return null;
  return (
    <div tw="my-6" {...rest}>
      <Link to={origin} tw="underline">
        Home
      </Link>
      <ArrowIcon tw="mx-3" />
      {post ? (
        <>
          <Link
            to={`/${post.articleType.replace(' ', '-').toLowerCase()}s/`}
            tw="underline"
          >{`${post.articleType}s`}</Link>
        </>
      ) : (
        <Link to={pathname} tw="capitalize">
          {pathname.replaceAll('/', '').replace('-', ' ')}
        </Link>
      )}
    </div>
  );
};

export default Breadcrumbs;
