import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ description, keywords, title, image, slug, articleType, dateTime }) => {
  const { datoCmsPage, datoCmsSite } = useStaticQuery(
    graphql`
      query seoQuery {
        datoCmsSite {
          name
          faviconMetaTags {
            tags
          }
        }
        datoCmsPage(title: { eq: "Blog" }) {
          title
          seo {
            title
            description
            image {
              url
            }
          }
        }
      }
    `
  );

  const metaDescription = description || datoCmsPage.seo.description;
  const metaTitle = title || datoCmsPage.seo.title;
  const metaKeywords = keywords || 'denmark, innovation, digital';
  const metaImage = image?.url || datoCmsPage.seo.image.url;
  const metaAuthor = datoCmsSite.name || 'Digital Hub Denmark';
  const metaIcons = datoCmsSite.faviconMetaTags.tags.map((icon) => icon.attributes);

  let bodyClass = null;
  if (articleType === 'Company Case' || articleType === 'Tech Talent') bodyClass = 'case';
  if (articleType === 'Article') bodyClass = 'article';

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      bodyAttributes={{ class: bodyClass && bodyClass }}
      link={[
        // { rel: 'preconnect dns-prefetch', href: 'https://www.google-analytics.com' },
        { rel: 'canonical', href: `https://blog.digitalhubdenmark.dk/${slug ? slug + '/' : ''}` },
        ...metaIcons,
      ]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: `https://blog.digitalhubdenmark.dk/${slug || ''}`,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:type',
          content: description ? 'article' : 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: metaAuthor,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'ahrefs-site-verification',
          content: '65497582d2bcb1db2b2ef6027252766c185635b1ed1b1330b796cf4460c29783',
        },
      ]}
    >
      {articleType && (
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': `https://blog.digitalhubdenmark.dk/${slug}`,
            },
            headline: title,
            description: metaDescription,
            image: [metaImage],
            datePublished: dateTime,
            dateModified: dateTime,
            author: {
              '@type': 'Person',
              name: 'Digital Hub Denmark',
              url: 'https://digitalhubdenmark.dk',
            },
            publisher: {
              '@type': 'Organization',
              name: 'Digital Hub Denmark',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.datocms-assets.com/40416/1610038023-digital-hub-denmark.png',
              },
            },
          })}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
