import React from 'react';
import tw, { styled } from 'twin.macro';

const Label = styled.span(({ label, inset }) => [
  tw`px-2 py-1 text-xs bg-blue text-white`,
  inset && tw`absolute top-4 left-4 z-10`,
  label === 'Company Case' && tw`bg-red`,
  label === 'Tech Talent' && tw`bg-yellow text-blue-dark`,
]);

const ContentLabel = ({ label, inset }) => {
  return (
    <Label label={label} inset={inset}>
      {label}
    </Label>
  );
};

export default ContentLabel;
